import React, { useContext } from 'react'
import BlogContext from '../../../contexts/blogData/blogData'
import {
  getIdsByCategory,
  getVideosByIds,
  randomizeVideos,
} from '../suggestedVideoHelpers'
import SuggestedVideos from '../SuggestedVideos'
import VideoContext from '../../../contexts/videoData/videoData'
import PropTypes from 'prop-types'

const computeAllRelatedVideos = (category, posts, videos) => {
  const allVideoIDsThatMatchCategory = getIdsByCategory(posts, category)
  return randomizeVideos(
    getVideosByIds(allVideoIDsThatMatchCategory, videos)
  ).slice(0, 3)
}
const AllRelatedVideosBySingleCategory = ({ category }) => {
  const allVideos = useContext(VideoContext)
  const allPosts = useContext(BlogContext)
  const suggestedVideos = computeAllRelatedVideos(category, allPosts, allVideos)

  return <SuggestedVideos videos={suggestedVideos} />
}

AllRelatedVideosBySingleCategory.propTypes = {
  category: PropTypes.string,
}

export default AllRelatedVideosBySingleCategory
