import React, { useState, useRef } from 'react'
import AllRelatedVideosBySingleCategory from '../components/SuggestedVideos/AllRelatedVideosBySingleCategory/AllRelatedVideosBySingleCategory'
import AnyVideos from '../components/SuggestedVideos/AnyVideos/AnyVideos'
import { BlogData } from '../contexts/blogData/blogData'
import { CampingData } from '../contexts/campingData/campingData'
import CategorySelector from '../components/Selectors/CategorySelector/CategorySelector'
import InformationContent from '../components/InformationContent/InformationContent'
import { LodgingData } from '../contexts/lodgingData/lodgingData'
import SEO from '../components/seo'
import SideBar from '../components/sideBar/SideBar'
import TwoColumnLayout from '../components/twoColumnLayout/TwoColumnLayout'
import { VideoData } from '../contexts/videoData/videoData'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import AdSense from 'react-adsense'
import MediaQuery from '../components/sharedComponents/MediaQuery/MediaQuery'

const queries = {
  mobile: '(max-width: 999px)',
  desktop: '(min-width: 1000px)',
}

const Information = ({ data, location }) => {
  const myRef = useRef(null)
  const [category, setCategory] = useState(
    location.state ? location.state.category : null
  )
  const onSetCategory = category => {
    setCategory(category)
  }

  const onButtonClick = () => {
    myRef.current.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    })
  }
  const matchPoints = MediaQuery(queries)
  return (
    <div ref={myRef}>
      <SEO
        title="Directions and Information | All Locations"
        description="Directions, phone numbers, website urls and
         maps for all locations visited by the Cactus Atlas"
        url="https://cactusatlas.com/information/"
        type="article"
      />
      <BlogData>
        <CampingData>
          <LodgingData>
            <VideoData>
              <TwoColumnLayout>
                <InformationContent
                  allImageData={data}
                  category={category ? category : null}
                  onSetCategory={category => onSetCategory(category)}
                  onButtonClick={() => onButtonClick()}
                />
                <SideBar>
                  {category ? (
                    <AllRelatedVideosBySingleCategory category={category} />
                  ) : (
                    <AnyVideos />
                  )}
                  <CategorySelector
                    pageType="information"
                    currentCategory={category}
                    onSetCategory={category => onSetCategory(category)}
                    onButtonClick={() => onButtonClick()}
                  />
                  {matchPoints && matchPoints.desktop ? (
                    <AdSense.Google
                      client="ca-pub-1156710604979342"
                      slot="8925894875"
                      style={{
                        display: 'inline-block',
                        width: '300px',
                        height: '250px',
                        position: 'sticky',
                        top: '184px',
                      }}
                    />
                  ) : null}
                </SideBar>
              </TwoColumnLayout>
            </VideoData>
          </LodgingData>
        </CampingData>
      </BlogData>
    </div>
  )
}

export const query = graphql`
  query {
    allFile(filter: { extension: { ne: "md" } }) {
      nodes {
        childImageSharp {
          id
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
          }
        }
        relativePath
        extension
      }
    }
  }
`

Information.propTypes = {
  location: PropTypes.object,
  data: PropTypes.object,
  onSetCategory: PropTypes.func,
  onButtonClick: PropTypes.func,
}

export default Information
