import React from 'react'
import Select from 'react-dropdown-select'
import PropTypes from 'prop-types'
import styles from './mobileCategorySelector.module.css'

const MobileCategorySelector = ({
  uniqueCategories,
  category,
  onSetCategory,
  onButtonClick,
  oniFrameLoaded,
}) => {
  const options = uniqueCategories

  const onCategorySelected = category => {
    if (category) {
      onSetCategory(category.value)
      onButtonClick()
      if (oniFrameLoaded) {
        oniFrameLoaded(false)
      }
    }
  }

  const values = category
    ? [options.find(option => option.value === category)]
    : []

  return (
    <div className={styles.selectWrapper}>
      <Select
        searchable={false}
        className={styles.dropdown}
        placeholder="Select Category"
        options={options}
        values={values}
        labelField="display"
        valueField="value"
        onChange={category => onCategorySelected(category[0])}
      />
    </div>
  )
}
MobileCategorySelector.propTypes = {
  category: PropTypes.string,
  onSetCategory: PropTypes.func,
  onButtonClick: PropTypes.func,
  oniFrameLoaded: PropTypes.func,
  uniqueCategories: PropTypes.array,
}
export default MobileCategorySelector
