import React from 'react'
import Img from 'gatsby-image'
import { IconContext } from 'react-icons'
import { FaCalendarAlt, FaGlobe, FaMap, FaMapMarkerAlt } from 'react-icons/fa'
import PropTypes from 'prop-types'
import styles from './mainInfo.module.css'

const MainInfo = ({
  address,
  phone_number,
  alt,
  fluidImg,
  rates,
  website,
  name,
  parent_facility,
  google_map_link,
  park_map,
  reservation_page,
  type,
}) => {
  const alternateReservationMessage =
    type === 'camping' ? (
      <span className={styles.firstCome}>
        * Campsites are First Come, First Serve
      </span>
    ) : null

  return (
    <div className={styles.mainInfo}>
      <div className={styles.hero}>
        <Img fluid={fluidImg} alt={alt} />
      </div>
      <div className={styles.info}>
        <div className={styles.placeInfo}>
          {parent_facility ? (
            <>
              <h2 className={styles.parentFacility}>{parent_facility}</h2>
              <div className={styles.rates}>{rates}</div>
            </>
          ) : (
            <h2 className={styles.parentFacility}>{rates}</h2>
          )}

          <div className={styles.infoField}>{address}</div>
          <div className={styles.infoField}>
            <a className={styles.link} href={`tel:${phone_number}`}>
              {phone_number}
            </a>
          </div>
        </div>
        <div className={styles.infoLinks}>
          <div className={styles.infoLink}>
            <IconContext.Provider
              className={styles.linkIcon}
              value={{ color: '#626262', size: '20px' }}
            >
              <FaMapMarkerAlt />
            </IconContext.Provider>
            <a
              className={`${styles.link} ${styles.iconLink}`}
              href={google_map_link}
              target="_blank"
              rel="noopener noreferrer"
            >
              View on Google Maps
            </a>
          </div>
          {website ? (
            <div className={styles.infoLink}>
              <IconContext.Provider value={{ color: '#626262', size: '20px' }}>
                <FaGlobe />
              </IconContext.Provider>
              <a
                className={`${styles.link} ${styles.iconLink}`}
                href={website}
                target="_blank"
                rel="noopener noreferrer"
              >
                {parent_facility || name}
              </a>
            </div>
          ) : null}
          {park_map ? (
            <div className={styles.infoLink}>
              <IconContext.Provider value={{ color: '#626262', size: '20px' }}>
                <FaMap />
              </IconContext.Provider>
              <a
                className={`${styles.link} ${styles.iconLink}`}
                href={park_map}
                target="_blank"
                rel="noopener noreferrer"
              >
                Park Map
              </a>
            </div>
          ) : null}
          {(reservation_page && type === 'camping') || type === 'lodging' ? (
            <div className={styles.infoLink}>
              <IconContext.Provider value={{ color: '#626262', size: '20px' }}>
                <FaCalendarAlt />
              </IconContext.Provider>
              <a
                className={`${styles.link} ${styles.iconLink}`}
                href={reservation_page}
                target="_blank"
                rel="noopener noreferrer"
              >
                {type === 'camping'
                  ? 'Reserve Your Campsite'
                  : 'Reserve Your Room'}
              </a>
            </div>
          ) : (
            alternateReservationMessage
          )}
        </div>
      </div>
    </div>
  )
}
MainInfo.propTypes = {
  address: PropTypes.string,
  phone_number: PropTypes.string,
  alt: PropTypes.string,
  fluidImg: PropTypes.object,
  rates: PropTypes.string,
  website: PropTypes.string,
  name: PropTypes.string,
  parent_facility: PropTypes.string,
  google_map_link: PropTypes.string,
  park_map: PropTypes.string,
  type: PropTypes.string,
  reservation_page: PropTypes.string,
}
export default MainInfo
