import React, { useContext } from 'react'
import BlogContext from '../../../contexts/blogData/blogData'
import CampingContext from '../../../contexts/campingData/campingData'
import { computeUniqueCategories } from '../../../helpers/helpers'
import LodgingContext from '../../../contexts/lodgingData/lodgingData'
import PropTypes from 'prop-types'
import styles from '../categorySelector.module.css'

const Category = ({
  category,
  selectedCategory,
  onSetCategory,
  onButtonClick,
}) => {
  return (
    <li>
      <button
        className={`${styles.categoryLink} ${
          selectedCategory === category.value ? styles.activeCategory : null
        }`}
        onClick={() => {
          onSetCategory(category.value)
          onButtonClick()
        }}
      >
        {category.display}
      </button>
    </li>
  )
}

const CategorySelector = ({
  pageType,
  currentCategory,
  onSetCategory,
  onButtonClick,
}) => {
  const adventures =
    pageType === 'adventure' ? [...useContext(BlogContext)] : []
  const camping = pageType === 'camping' ? [...useContext(CampingContext)] : []
  const lodging = pageType === 'lodging' ? [...useContext(LodgingContext)] : []
  const information =
    pageType === 'information'
      ? [
          ...useContext(BlogContext),
          ...useContext(CampingContext),
          ...useContext(LodgingContext),
        ]
      : []
  const uniqueCategories = computeUniqueCategories([
    ...adventures,
    ...camping,
    ...lodging,
    ...information,
  ]).filter(
    category => category.value !== 'camping' && category.value !== 'lodging'
  )
  const categories = uniqueCategories.map((category, index) => (
    <Category
      key={index}
      category={category}
      selectedCategory={currentCategory}
      onSetCategory={category => onSetCategory(category)}
      onButtonClick={() => onButtonClick()}
    />
  ))

  return (
    <div>
      <h3 className={styles.categoriesHeader}>Categories</h3>
      <ul>{categories}</ul>
    </div>
  )
}

Category.propTypes = {
  pageType: PropTypes.string,
  category: PropTypes.object,
  selectedCategory: PropTypes.string,
  onSetCategory: PropTypes.func,
  onButtonClick: PropTypes.func,
}

CategorySelector.propTypes = {
  pageType: PropTypes.string,
  currentCategory: PropTypes.string,
  onSetCategory: PropTypes.func,
  onButtonClick: PropTypes.func,
}
export default CategorySelector
