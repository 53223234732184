export const computePriceRange = prices => {
  if (prices && prices.length <= 1) {
    const price = prices[0]
    return `Admission: ${price.value}`
  }

  const validPrices = prices
    .filter(price => price.value.includes('$'))
    .map(price => parseFloat(price.value.replace('$', '')))
  return `Admission: ${Math.min(...validPrices).toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
  })} - ${Math.max(...validPrices).toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
  })}`
}
