import React, { useContext } from 'react'
import ArticleButton from '../../components/sharedComponents/articleButton/articleButton'
import ArticleTitle from '../../components/sharedComponents/ArticleTitle/ArticleTitle'
import BlogContext from '../../contexts/blogData/blogData'
import CampingContext from '../../contexts/campingData/campingData'
import { computePriceRange } from '../../components/InformationPages/helpers'
import { computeUniqueCategories } from '../../helpers/helpers'
import { getFluidImage } from '../../helpers/dynamicGatsbyImage'
import LodgingContext from '../../contexts/lodgingData/lodgingData'
import MainInfo from '../../components/InformationPages/MainInfo/MainInfo'
import MediaQuery from '../sharedComponents/MediaQuery/MediaQuery'
import MobileCategorySelector from '../Selectors/MobileCategorySelector/MobileCategorySelector'
import { orderBy } from 'lodash'
import Title from '../sharedComponents/Title/Title'
import PropTypes from 'prop-types'
import styles from '../../page-css/information.module.css'

const queries = {
  mobile: '(max-width: 999px)',
  desktop: '(min-width: 1000px)',
}

const InformationContent = ({
  allImageData,
  category,
  onSetCategory,
  onButtonClick,
}) => {
  const matchPoints = MediaQuery(queries)
  const posts = category
    ? orderBy(
        [
          ...useContext(LodgingContext),
          ...useContext(CampingContext),
          ...useContext(BlogContext),
        ],
        ['date'],
        ['desc']
      )
        .filter(post => {
          if (post.categories.find(y => y === category)) {
            return post
          }
        })
        .map((post, index) => {
          const fluidImg = getFluidImage(post, allImageData.allFile.nodes)
          const {
            alt,
            rates,
            prices,
            phone_number,
            address,
            website,
            google_map_link,
            reservation_page,
            parent_facility,
            park_map,
            type,
            displayName,
            info_summary,
            overview,
            slug,
          } = post
          const priceRange =
            type === 'adventures' ? computePriceRange(prices) : null
          return (
            <div key={index} className={styles.infoBox}>
              <ArticleTitle>{displayName}</ArticleTitle>
              <MainInfo
                fluidImg={fluidImg}
                alt={alt}
                rates={priceRange || rates}
                phone_number={phone_number}
                address={address}
                website={website}
                name={displayName}
                google_map_link={google_map_link}
                reservation_page={reservation_page}
                parent_facility={parent_facility}
                park_map={park_map}
                type={type}
              />
              <div className={styles.summary}>{overview || info_summary}</div>
              <div className={styles.articleLinks}>
                <ArticleButton
                  icon="info"
                  url={`/information/${slug}`}
                  color="#655128"
                  text="All Information"
                />
              </div>
            </div>
          )
        })
    : orderBy(
        [
          ...useContext(BlogContext),
          ...useContext(CampingContext),
          ...useContext(LodgingContext),
        ],
        ['date'],
        ['desc']
      ).map((post, index) => {
        const fluidImg = getFluidImage(post, allImageData.allFile.nodes)
        const {
          alt,
          rates,
          prices,
          phone_number,
          address,
          website,
          google_map_link,
          reservation_page,
          parent_facility,
          park_map,
          type,
          displayName,
          info_summary,
          overview,
          slug,
        } = post
        const priceRange =
          type === 'adventures' ? computePriceRange(prices) : null

        return (
          <div key={index} className={styles.infoBox}>
            <ArticleTitle>{displayName}</ArticleTitle>
            <MainInfo
              fluidImg={fluidImg}
              alt={alt}
              rates={priceRange || rates}
              name={displayName}
              phone_number={phone_number}
              address={address}
              website={website}
              google_map_link={google_map_link}
              reservation_page={reservation_page}
              parent_facility={parent_facility}
              park_map={park_map}
              type={type}
            />
            <div className={styles.summary}>{overview || info_summary}</div>
            <div className={styles.articleLinks}>
              <ArticleButton
                icon="info"
                url={`/information/${slug}`}
                color="#9f211c"
                text="All Information"
              />
            </div>
          </div>
        )
      })

  const uniqueCategories = computeUniqueCategories([
    ...useContext(BlogContext),
    ...useContext(CampingContext),
    ...useContext(LodgingContext),
  ])
  const title = category ? `${category} Information` : 'All Information'

  return (
    <div>
      {matchPoints && matchPoints.mobile ? (
        <MobileCategorySelector
          category={category}
          onSetCategory={category => onSetCategory(category)}
          onButtonClick={() => onButtonClick()}
          uniqueCategories={uniqueCategories}
        />
      ) : null}
      {matchPoints && matchPoints.desktop ? <Title title={title} /> : null}

      {posts.length ? (
        posts
      ) : (
        <div className={styles.noMatch}>
          Sorry, there are no posts matching the category {`'${category}'`}
        </div>
      )}
    </div>
  )
}
InformationContent.propTypes = {
  category: PropTypes.string,
  allImageData: PropTypes.object,
  onSetCategory: PropTypes.func,
  onButtonClick: PropTypes.func,
}
export default InformationContent
