import React, { useContext } from 'react'
import BlogContext from '../../../contexts/blogData/blogData'
import CampingContext from '../../../contexts/campingData/campingData'
import { getAllVideos, randomizeVideos } from '../suggestedVideoHelpers'
import LodgingContext from '../../../contexts/lodgingData/lodgingData'
import SuggestedVideos from '../SuggestedVideos'
import VideoContext from '../../../contexts/videoData/videoData'

const computeAnyVideos = (allVideos, allPosts) => {
  return randomizeVideos(getAllVideos(allVideos, allPosts)).slice(0, 3)
}

const AnyVideos = () => {
  const allVideos = useContext(VideoContext)
  const allPosts = [
    ...useContext(BlogContext),
    ...useContext(CampingContext),
    ...useContext(LodgingContext),
  ]
  return <SuggestedVideos videos={computeAnyVideos(allVideos, allPosts)} />
}

export default AnyVideos
